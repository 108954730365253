/* Technical Debt: duplicate from index.html */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@font-face {
  font-family: 'icomoon';
  src: url('../src/assets/sidebarFonts/icomoon.eot?bzbzhm');
  src:
    url('../src/assets/sidebarFonts/icomoon.svg?bzbzhm#icomoon') format('svg'),
    url('../src/assets/sidebarFonts/icomoon.ttf?bzbzhm') format('truetype'),
    url('../src/assets/sidebarFonts/icomoon.woff?bzbzhm') format('woff'),
    url('../src/assets/sidebarFonts/icomoon.eot?bzbzhm#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-incidents:before {
  content: '\e900';
}
.icon-lineman:before {
  content: '\e901';
}
.icon-onboarding:before {
  content: '\e902';
}
.icon-my-rosters:before {
  content: '\e903';
}
.icon-company:before {
  content: '\e904';
}
.icon-notification:before {
  content: '\e905';
}
.icon-my-approvals:before {
  content: '\e906';
}
.icon-Settings:before {
  content: '\e907';
}
.icon-fleet:before {
  content: '\e908';
}
.icon-profile:before {
  content: '\e909';
}

body {
  margin: 0;
  font-family:
    'Poppins',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
